@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'components/icons';

@layer utilities {
  ::after {
    box-sizing: initial;
  }
}

@layer base {
  .collapse {
    visibility: initial;
  }
}

html {
  @apply text-slate-700 break-all;
}

* {
  @apply font-normal p-0;
}

h3 {
  @apply text-lg;
}

#footer a {
  @apply text-white;
}

abbr[title] {
  @apply no-underline;
}

g text tspan {
  @apply font-bold;
}
